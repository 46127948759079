import { template as template_649e3d5ea07141f8a90168d108eaa7f9 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_649e3d5ea07141f8a90168d108eaa7f9(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
