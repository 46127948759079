import { template as template_f8862c26b5f843baade072ebecccac8e } from "@ember/template-compiler";
const SidebarSectionMessage = template_f8862c26b5f843baade072ebecccac8e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
